
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons);

// Variables
//@import "variables";
//@import '../node_modules/vuetify/dist/vuetify.min';

// Bootstrap
//@import "node_modules/bootstrap/scss/bootstrap";
//button .v-btn__content { color: #000; }